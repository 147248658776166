.cookie-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f5f5f5;
    padding: 20px;
    z-index: 9999;
    border: 5px solid #202020;
    border-radius: 1%;
}
.cookie-header {
    color: #404040;
}
.cookie-text {
    background-color: #f5f5f5;
    padding-bottom: 10px;
}
.cookie-btn {
    background-color: #404040;
    color: #f5f5f5;
    border: none;
}
@media only screen and (max-width: 786px) {
    .cookie-container {
        width: 90%;
        padding: 10px;
    }
    .cookie-header {
        font-size: 25px;
    }
    .cookie-text {
        font-size: 20px;
    }
    .cookie-btn {
        font-size: 20px;
        padding: 5px 10px;
    }
}