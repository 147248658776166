body {
  background-color: #F5F5F5;
  font-family: FiraSans, sans-serif;
  margin: 0;
  border: none;
  text-shadow: 0 0 0px black;
}
.content {
  padding: 40px 20px;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
h1 {
  font-family: FiraSans, sans-serif;
  color: #404040;
  text-shadow: 0 0 0px black;
  margin: 10px 0px;
  font-size: 3em;
}
h3 {
  font-family: FiraSans, sans-serif;
  color: #404040;
  text-shadow: 0 0 0px black;
  font-size: 1.5em;
  margin: 0;
}
p, label, .content-li {
  font-family: FiraSans, sans-serif;
  margin: 0;
  color: #404040;
  font-size: 1.25em;
  margin-right: 15px;
}
.single-text {
  margin: 25px 0px;
}
.content-li {
  margin: 6px 0px;
  margin-right: 15px;
}
img {
  max-width: 100%;
  display: block;
  min-width: 100%;
}
.navbar {
  display: flex;
  flex-direction: row;
  background-color: #404040;
  border: none;
  justify-content: center;
}
.mobile-menu-button {
  display: none;
  background-color: #404040;
}
button {
  background-color: #404040;
  padding: 10px 20px;  
  text-align: center;
  color: #F5F5F5;
  font-family: FiraSans, sans-serif;
  text-shadow: 0 0 0px black;
  font-size: 20px;
  border: 0;
  transition: all 0.4s ease;
}
button:hover {
  transform: scale(1.05);
  background-color: #5A5A5A;
}

footer {
  background-color: #404040;
  color: #F5F5F5;
  padding: 50px;
  padding-top: 25px;
  font-size: 1.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

footer li {
  margin-top: 25px;
  list-style-type: none;
}

@media only screen and (max-width: 786px) {
  .mobile-menu-button {
    display: block;
  }
  .navbar {
    display: none;
    flex-direction: column;
    
  }
  .navbar.open {
    display: flex;
  }
  .content {
    padding-left: 2%;
  }
  footer {
    flex-direction: column;
  }
  .container-left,
  .container-right {
    width: 100%;
  }
}