.react-slideshow-container {
    max-width: 1024px;
    position: relative;
    margin: 0 auto;
}

.each-slide-effect > div {
    justify-content: center;
    background-size: cover;
    height: 750px;
    width: 750px;
    margin: 5px auto;
    border-radius: 5px; 
}
.gallery .react-slideshow-container .default-nav {
    height: 50px;
    width: 50px;
}
@media only screen and (max-width: 786px) {
    .each-slide-effect > div {
        margin: auto auto;
        height: 500px;
        width: 500px;
    }
}